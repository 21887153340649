require('./wnumb');
require('./rangeselector');
import axios from 'axios';

import { Iodine } from '@kingshott/iodine';
const iodine = new Iodine();

var moment = require('moment'); // require

export default {

    homeCarrousel: function() {
        return {
            slider: null,
            currentSlide: 0,
            totalSlides: 0,

            init: function() {
                return this.createSwiper();
            },

            createSwiper: function() {
                if (this.slider) {
                    return;
                }

                this.slider = new Swiper(this.$refs.homeCarrousel, {
                    slidesPerView: 1,
                    initialSlide: 0,
                    speed: 1000,
                    autoplay: {
                        delay: 5000,
                    },
                    disableOnInteraction: false,
                    loop: true,
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    spaceBetween: 0,
                    grabCursor: true,
                    threshold: 30,
                    pagination: false,
                });
            }
        };
    },

    mobileSlider: function() {
        return {
            slider: null,

            init: function() {
                if (window.innerWidth < 920 && !this.slider) {
                    this.createSwiper();
                    return;
                }
                if (window.innerWidth >= 920 && this.slider) {
                    this.destroySwiper();
                    return;
                }
            },

            createSwiper: function() {
                this.slider = new Swiper(this.$el, {
                    slidesPerView: 1,
                    initialSlide: 0,
                    centeredSlides: true,
                    speed: 1000,
                    spaceBetween: 0,
                    grabCursor: true,
                    watchSlidesProgress: true,
                    disableOnInteraction: false,
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 2,
                            centeredSlides: false,
                        }
                    }
                });
            },

            destroySwiper: function() {
                if (this.slider) {
                    this.slider.destroy();
                    this.slider = undefined;
                }
            }
        }
    },

    mobileTwoSlider: function() {
        return {
            slider: null,

            init: function() {
                if (window.innerWidth < 768 && !this.slider) {
                    this.createSwiper();
                    return;
                }
                if (window.innerWidth >= 768 && this.slider) {
                    this.destroySwiper();
                    return;
                }
            },

            createSwiper: function() {
                this.slider = new Swiper(this.$el, {
                    slidesPerView: 1,
                    initialSlide: 0,
                    centeredSlides: true,
                    speed: 1000,
                    spaceBetween: 0,
                    grabCursor: true,
                    watchSlidesProgress: true,
                    disableOnInteraction: false,
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                });
            },

            destroySwiper: function() {
                if (this.slider) {
                    this.slider.destroy();
                    this.slider = undefined;
                }
            }
        }
    },

    priceSlider: function(range) {
        return {
            slider: null,
            range: range,

            init: function() {
                return this.createPriceSlider();
            },

            createPriceSlider: function() {
                let min = 0;
                let max = this.range.length - 1;
                this.$refs.minLabel.innerHTML = this.range[0];
                this.$refs.maxLabel.innerHTML = this.range[this.range.length - 1];

                noUiSlider.create(this.$refs.slider, {
                    start: [min, max],
                    connect: true,
                    step: 1,
                    animate: false,
                    tooltips: false,
                    format: wNumb({
                        decimals: 0
                    }),
                    range: {
                        min: min,
                        max: max
                    }
                });

                this.$refs.slider.noUiSlider.on('update', (values, handle) => this.updateSlider(values, handle));
            },

            updateSlider: function(values){
                this.$refs.minLabel.innerHTML = this.range[values[0]];
                this.$refs.maxLabel.innerHTML = this.range[values[1]];
            }
        }
    },

    makeAppointment: function(lastStep) {
        return {
            formData: {},
            options: {
                headers: {
                    'Access-Control-Allow-Origin': 'true',
                    'Content-Type': 'application/json',
                }
            },
            baseUrl: '/web/restproxy/',
            // baseUrl: 'https://a-point.devel.gxsoftware.com/web/restproxy/',
            activeStep: 1,
            toBeFinishedStep: 1,
            lastStep: lastStep,
            appointmentDate: null,
            minMonth: null,
            maxMonth: null,
            selectedBrandId: null,
            selectedEstablishmentId: null,
            selectedYear: moment().year(),
            selectedMonth: moment().month(),
            selectedDay: null,
            selectedTime: null,
            selectedReplacementVehicle: null,
            errors: [],
            brands: [],
            establishments: [],
            totalAvailableDates: [],
            availableTimes: [],
            filteredJobCategories: [],
            selectedJobs: [],
            availableReplacementVehicles: [],
            selectedMainJobId: null,
            regstrationNumberHasBeenSearched: false,
            showErrorConfirmAppointment: false,
            brandNotAvailable: false,
            availableJobCategories: {
                '10': 'Onderhoud',
                '20': 'Banden',
                '30': 'APK',
                '40': 'Schade',
                '50': 'Campagne',
                '60': 'Terugroepactie',
                '70': 'Reparatie',
                '80': 'Airco',
                '99': 'Overig',
            },

            init: function() {
                this.defineCustomValidationRules();

                this.$watch('selectedEstablishmentId', () => {
                    this.selectedJobs = [];
                    this.selectedMainJobId = null;

                    let establishment = this.filteredEstablishments.find((establishment) => establishment.Id === parseInt(this.selectedEstablishmentId));

                    this.filteredJobCategories = [];
                    for (const [key, value] of Object.entries(this.groupBy(establishment.Jobs, 'CategoryId'))) {
                        if(parseInt(key) !== 0){
                            this.filteredJobCategories.push({
                                'category': this.availableJobCategories[key],
                                'jobs': value
                            });
                        }else{
                            this.filteredJobCategories.push({
                                'category': 'Geen',
                                'jobs': value
                            });
                        }
                    }

                    this.$refs['jobs-checkbox-wrapper'].querySelectorAll('.custom-checkbox').forEach(input => input.checked = false)
                    this.$refs['jobs-waiting-checkbox-wrapper'].querySelectorAll('.custom-checkbox').forEach(input => input.checked = false)
                })

                axios.get(this.baseUrl + 'GetEstablishmentsInfo', this.options)
                .then((response) => {
                    this.establishments = response.data.Establishments;
                    let uniqueBrandIds = [];
                    this.brands = response.data.Establishments.flatMap((establishment) => establishment.Makes).map(function(brand){
                        if(!uniqueBrandIds.includes(brand.Id)){
                            uniqueBrandIds.push(brand.Id)
                            return brand;
                        }
                    }).filter((brand) => brand !== undefined);
                });
            },

            get disableFirstStepButton(){
                if(!this.formData.Mileage){
                    return true;
                }

                if(this.formData.Mileage.length < 3){
                    return true;
                }

                if(!this.selectedBrandId){
                    return true;
                }

                if(this.errors.length > 0){
                    return true;
                }

                if(this.brandNotAvailable){
                    return true;
                }

                return false;
            },

            fetchAllEstablishments: function(){

                let fetchUrl = this.baseUrl + 'GetEstablishmentsInfo?registrationNumber=' + this.formData.RegistrationNumber + '&mileage=' + this.formData.Mileage;

                if(this.selectedBrandId !== 999999){
                    fetchUrl += '&makeId=' + this.selectedBrandId;
                }

                axios.get(fetchUrl, this.options)
                .then((response) => {
                    this.establishments = response.data.Establishments;
                    this.nextStep();
                });
            },

            get hideReplacementVehicleChoice(){
                if(this.selectedJobs.length === 0){
                    false;
                }

                return this.selectedJobs.find(job => job.Waits);
            },

            get selectedBrand(){
                if(!this.selectedBrandId || this.brands.length === 0){
                    false;
                }

                return this.brands.find(brand => parseInt(brand.Id) === parseInt(this.selectedBrandId));
            },

            get selectedSalutation(){
                if(!this.formData.Salutation || this.formData.Salutation === ''){
                    return 'Aanhef'
                }

                return this.formData.Salutation === 'heer' ? 'De heer' : 'Mevrouw';
            },

            fetchRegistrationNumberInformation: function(){
                this.regstrationNumberHasBeenSearched = false;
                this.brandNotAvailable = true;

                if(this.errors.includes('RegistrationNumber') || this.formData.RegistrationNumber.replaceAll('-', '').length < 6){
                    return;
                }

                axios.get(this.baseUrl + 'kenteken?kenteken=' + this.formData.RegistrationNumber.replaceAll('-', '').toUpperCase(),
                    this.options)
                .then((response) => {
                    if(response.data.length === 0){
                        this.regstrationNumberHasBeenSearched = true;
                        return this.brandNotAvailable = true;
                    }

                    this.brandNotAvailable = false;

                    this.formData.Type = response.data[0].handelsbenaming;
                    this.formData.Make = (response.data[0].voertuigsoort === 'Bedrijfsauto' && response.data[0].merk === 'VOLKSWAGEN') ? 'VW BEDRIJFSWAGENS' : response.data[0].merk;

                    if(this.formData.Make === 'VW'){
                        this.formData.Make = 'VOLKSWAGEN';
                    }

                    let brandMatch = this.brands.find((brand) => brand.Name.toLowerCase() === this.formData.Make.toLowerCase());
                    if(brandMatch){
                        this.selectedBrandId = brandMatch.Id;
                    }else{
                        this.brandNotAvailable = true;
                    }

                    this.regstrationNumberHasBeenSearched = true;
                });

            },

            groupBy: function(xs, key){
                return xs.reduce(function(rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x);
                    return rv;
                  }, {});
            },

            //START STEP 2
            get filteredEstablishments() {
                if(!this.selectedBrandId){
                    return;
                }

                if(this.selectedBrandId === 999999){
                    return this.establishments;
                }

                return this.establishments.filter(establishment => establishment.Makes.map(brand => brand.Id).includes(parseInt(this.selectedBrandId)));
            },

            get selectedEstablishment() {
                if(!this.selectedEstablishmentId){
                    return null;
                }

                return this.filteredEstablishments.find((establishment) => establishment.Id === parseInt(this.selectedEstablishmentId));
            },
            //END STEP 2

            //START STEP 3
            updateSelectedJobs: function(event, job){
                if(!event.target.checked){
                    return this.selectedJobs.splice(this.selectedJobs.findIndex((selectedJob) => selectedJob.JobID === job.Id), 1);
                }

                this.selectedJobs.push({
                    JobID: job.Id,
                    Units: job.Units,
                    Skillcode: '',
                    Name: job.Name,
                    Type: job.Type,
                    Price: job.Price,
                    DiscountPrice: job.DiscountPrice,
                    Code: job.Code,
                    Time: job.Time,
                    Waits: job.CustomerWaiting,
                });
            },

            pluck: function(array,key) {
                return array.map(a => a[key])
            },

            get showWaitServiceOption() {
                if(this.selectedJobs.length === 0){
                    return true;
                }

                let timeArray = this.pluck(this.selectedJobs, 'Time');

                const found = timeArray.find(element => parseInt(element) > 150);

                return found === undefined;
            },

            get canProceedToStep4() {

                if(this.selectedMainJobId){
                    return true;
                }

                if(this.selectedJobs.length > 1){
                    return true;
                }

                if(this.selectedJobs.length === 1 && !this.selectedJobs[0].Waits){
                    return true;
                }

                if(this.selectedJobs.length === 1 && this.selectedJobs[0].Waits && this.selectedMainJobId){
                    return true;
                }

            },
            //END STEP 3

            //START STEP 4
            fetchReplacementVehicleAvailability: function() {
                if(!this.selectedDay){
                    return;
                }

                axios.get(this.baseUrl + 'ReplacementVehicleAvailability'
                    + '?establishmentId=' + this.selectedEstablishmentId
                    + '&from=' + this.selectedDay.format('YYYY-MM-DD')
                    + '&till=' + this.selectedDay.format('YYYY-MM-DD'),
                    this.options)
                .then((response) => {
                    this.availableReplacementVehicles = this.selectedEstablishment.ReplacementVehicles.map(function(replacementVehicle){

                        let item = response.data.Availability.find(function(availableReplacementVehicle){
                            return availableReplacementVehicle.Code === replacementVehicle.Code;
                        });

                        if(replacementVehicle.Code === '' || replacementVehicle.Code == null){
                            replacementVehicle.Available = 999;
                            return replacementVehicle;
                        }

                        if(item === undefined){
                            return null;
                        }

                        replacementVehicle.Available = item.Available;

                        return replacementVehicle;
                    }).filter((replacementVehicle) => replacementVehicle !== null);
                });
            },

            get replacementVehiclesContainsCar() {
                if(this.availableReplacementVehicles.length === 0){
                    return;
                }

                return this.availableReplacementVehicles.find(vehicle => vehicle.Description.includes('auto') && vehicle.Available > 0);
            },

            get replacementVehiclesContainsBike() {
                if(this.availableReplacementVehicles.length === 0){
                    return;
                }

                return this.availableReplacementVehicles.find(vehicle => vehicle.Description.includes('fiets') && vehicle.Available > 0);
            },

            get replacementVehiclesContainsPublicTransport() {
                if(this.availableReplacementVehicles.length === 0){
                    return;
                }

                return this.availableReplacementVehicles.find(vehicle => vehicle.Description.includes('OV') && vehicle.Available > 0);
            },

            get currentMonth() {
                return moment().month(this.selectedMonth).year(this.selectedYear)
            },

            selectDay: function(day){
                if(this.selectedDay === day){
                    return;
                }

                this.selectedTime = null;

                axios.get(this.baseUrl + 'GetAvailableTimes'
                    + '?establishmentId=' + this.selectedEstablishmentId
                    + '&mainJob=' + this.selectedMainJobId
                    + '&extraJobs=' + JSON.stringify(this.formatJobsForFetchingDaysAndTimes())
                    + '&date=' + day.format('YYYY-MM-DD'),
                    this.options)
                .then((response) => {
                    this.availableTimes = response.data.Available;
                });

                this.selectedDay = day;
                this.fetchReplacementVehicleAvailability();
            },

            getDates: function(){
                axios.get(this.baseUrl + 'GetAvailableDates'
                    + '?establishmentId=' + this.selectedEstablishmentId
                    + '&mainJob=' + this.selectedMainJobId
                    + '&extraJobs=' + JSON.stringify(this.formatJobsForFetchingDaysAndTimes()),
                    this.options)
                .then((response) => {
                    this.totalAvailableDates = response.data.Available
                    let firstAvailableDate = this.totalAvailableDates.find(element => element.Available === 1 || element.Available === 2);

                    if(firstAvailableDate !== undefined){
                        this.setMonthAndYear(moment(firstAvailableDate.Date))
                    }

                    this.setMinAndMaxMonth();
                });
            },

            setMinAndMaxMonth: function(){
                if(!this.totalAvailableDates.length) {
                    return;
                }

                this.minMonth = moment(this.totalAvailableDates[0].Date).month();
                this.maxMonth = moment(this.totalAvailableDates[this.totalAvailableDates.length -1].Date).month();
            },

            formatAvailableDates: function(){
                return this.totalAvailableDates.map(function(availableDate){
                    return {
                        'date': moment(availableDate.Date),
                        'available': availableDate.Available,
                        'discount': availableDate.Discount
                    }
                }, this);
            },

            get availableDatesPerMonth(){
                let totalAvailableDatesThisMonth = this.formatAvailableDates().filter( (availableDate) => moment(availableDate.date).month() === parseInt(this.selectedMonth));

                //Add dates to fill op the gaps at the start of the month
                let dayNumber = this.currentMonth.startOf('month').day()

                // If start of month is a sunday, pad 6 days at the start
                // Otherwise end of last month won't be padded correctly
                // Since sunday's index is zero (0)
                if(dayNumber === 0) {
                    dayNumber = 7 // @casberkman1 workaround
                }

                if(dayNumber > 1){
                    for (var i = 1; i < dayNumber; i++) {
                        totalAvailableDatesThisMonth.unshift({
                            'extra': true,
                            'available': '-1',
                            'discount': '',
                            'date': ''
                        })
                    }
                }

                return totalAvailableDatesThisMonth;
            },

            setMonthAndYear: function(date){
                this.resetDayAndTime();
                this.selectedMonth = date.month();
                this.selectedYear = date.year();
            },

            addMonth: function(){
                this.resetDayAndTime();
                this.selectedMonth = moment().month(this.selectedMonth + 1).month();

                if(this.selectedMonth === 0){
                    this.selectedYear++
                }
            },

            subtractMonth: function(){
                this.resetDayAndTime();

                this.selectedMonth = moment().month(this.selectedMonth - 1).month();

                if(this.selectedMonth === 11){
                    this.selectedYear--
                }
            },

            resetDayAndTime: function(){
                this.selectedDay = null;
                this.selectedTime = null;
            },
            //END STEP 4

            //START STEP 6
            get canConfirmAppointment() {
                return this.formData.Salutation && this.formData.Name && this.formData.FirstName && this.formData.Phone && this.formData.Email && this.formData.Terms && this.errors.length === 0;
            },

            formatAllJobsForConfirmingAppointment: function(){
                if (!this.selectedMainJobId){
                    return this.selectedJobs;
                }

                let job = this.selectedEstablishment.Jobs.find(job => job.Id === parseInt(this.selectedMainJobId));

                this.selectedJobs.push({
                    JobID: job.Id,
                    Units: job.Units,
                    Skillcode: '',
                    Name: job.Name,
                    Type: job.Type,
                    Price: job.Price,
                    DiscountPrice: job.DiscountPrice,
                    Code: job.Code,
                    Waits: job.CustomerWaiting,
                });

                return this.selectedJobs;
            },

            getSelectedReplacementVehicle: function(){
                if(!this.hideReplacementVehicleChoice){
                    return this.selectedReplacementVehicle;
                }

                return this.selectedEstablishment.ReplacementVehicles.find((replacementVehicle) => replacementVehicle.Description === 'Geen').Id;
            },

            confirmAppointment: function(){
                //if mainJobId is selected, find the job object in the array of jobs of the selectedEstablishment
                let totalJobs = this.formatAllJobsForConfirmingAppointment();
                let self = this;
                var csrfToken = csrfProtection.getCsrfToken();

                axios.post(this.baseUrl + 'ConfirmAppointment?csrftoken=' + csrfToken + '&establishmentId=' + this.selectedEstablishmentId, {
                    AppointmentInfo: {
                        Date: this.selectedDay.format('YYYY-MM-DD'),
                        Time: this.selectedTime,
                        Remarks: this.formData.Remarks,
                        ReplacementVehicle: this.selectedReplacementVehicle,
                        SendMailCustomer: 1,
                        SendMailDealer: 1,
                        NoLead: 0,
                    },
                    Jobs: totalJobs,
                    Fields: this.prepareFieldsForConfirmingAppointment()
                }, this.options)
                .then((response) => {
                    if(response.data.Response !== 'OK'){
                        return self.showErrorConfirmAppointment = true;
                    }

                    this.nextStep();
                });
            },


            prepareFieldsForConfirmingAppointment: function(){
                let fields = this.formData;

                if(fields.HouseNumber){
                    fields.HouseNumber += fields.Addition ?? '';
                }

                delete fields["Addition"]
                delete fields["Terms"]
                delete fields["Remarks"]

                return fields;
            },
            //END STEP 6

            //START GENERAL
            get activeStepLabel(){
                let labels = {
                    1: 'De auto',
                    2: 'Waar',
                    3: 'Waarvoor',
                    4: 'Wanneer',
                    5: 'Vervoer',
                    6: 'Afronden',
                    7: 'Afspraak'
                };

                return labels[this.activeStep];
            },

            formatJobsForFetchingDaysAndTimes: function(){
                return this.selectedJobs.map(function(job){
                    return {
                        JobID: job.JobID,
                        Units: job.Units,
                        Skillcode: job.Skillcode,
                        Waits: job.Waits,
                    }
                });
            },

            validate (field, value, rules) {
                if(!iodine.isValid(value, rules)){
                    if(this.errors.indexOf(field) === -1){
                        this.errors.push(field);
                    }
                }else{
                    this.errors.splice(this.errors.indexOf(field), 1)
                }
                return;
            },

            nextStep: function(){
                if(this.activeStep == this.toBeFinishedStep){
                    this.toBeFinishedStep++;

                    if(this.activeStep === 4 && this.hideReplacementVehicleChoice){
                        this.toBeFinishedStep++;
                    }
                }

                if(this.activeStep < this.lastStep){
                    this.activeStep++;

                    if(this.activeStep === 5 && this.hideReplacementVehicleChoice){
                        this.activeStep++;
                    }
                }
            },

            previousStep: function(){
                if(this.activeStep > 1){
                    this.activeStep--;
                }
            },

            isActiveStep: function (step){
                return this.activeStep === step;
            },

            isFinishedStep: function(step){
                return this.toBeFinishedStep > step;
            },

            goToStep: function(step){
                if(this.toBeFinishedStep < step || this.activeStep == this.lastStep || this.errors.length){
                    return;
                }

                this.activeStep = step;
            },

            defineCustomValidationRules: function(){
                iodine.addRule('equals', function(value, param){
                    return value.length === parseInt(param);
                });

                iodine.addRule('min', function(value, param){
                    return value.length > parseInt(param);
                });

                iodine.addRule('max', function(value, param){
                    return value.length < parseInt(param);
                });

                iodine.addRule('zipcode', function(value){
                    let regex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;

                    return regex.test(value)
                });

                iodine.addRule('licenseplate', function(value){
                    if(value.match(/-/g) && value.match(/-/g).length === 1){
                        return false;
                    }

                    return value.replace(/-/g, "").length === 6;
                });
            },
            //END GENERAL
        }

    }
}
